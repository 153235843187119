import * as authConstants from "../auth/constants";
import * as constants from "./constants";

import { type PreferencesState } from "./types";
import { REHYDRATE } from "redux-persist";
import { type SharedRootAction } from "../types";

const initialState = {
  debugEnabled: false,
  showDraftComponents: false,
  activeCourse: undefined,
  activeProduct: undefined,
  productOptions: {},
  forum: undefined,
};

export default function preferencesReducer(state: PreferencesState = initialState, action: SharedRootAction) {
  switch (action.type) {
    case REHYDRATE: {
      if (action.payload && action.payload.preferences) {
        return {
          ...state,
          ...action.payload.preferences,
        };
      }
      return state;
    }
    case constants.DEBUG:
      return {
        ...state,
        debugEnabled: action.payload,
      };
    case constants.SHOW_DRAFT_COMPONENTS: {
      return {
        ...state,
        showDraftComponents: action.payload,
      };
    }
    // Changes the active product and course.
    // Triggered from a Saga initiator on CourseSelectionScreen
    case constants.SET_ACTIVE_COURSE_AND_PRODUCT: {
      return {
        ...state,
        activeCourse: action.payload.course,
        activeProduct: action.payload.product,
      };
    }
    // Updates the product level. Dispatched from the dashboard
    case constants.SET_ACTIVE_PRODUCT:
      if (state.activeProduct === action.payload.product) {
        return state;
      }
      return {
        ...state,
        activeProduct: action.payload.product,
      };
    // Updates the course.
    case constants.SET_ACTIVE_COURSE: {
      return {
        ...state,
        activeCourse: action.payload.course,
      };
    }
    // Resets active course and product when logging out
    case authConstants.LOGOUT:
      return {
        ...state,
        activeCourse: undefined,
        activeProduct: undefined,
      };
    case constants.SET_PRODUCT_LANGUAGE_FILTER: {
      const productOptions = state.productOptions || {};
      let updatedOptions;
      if (productOptions[action.payload.productId]) {
        updatedOptions = {
          ...productOptions[action.payload.productId],
          filterLanguageId: action.payload.languageId,
        };
      } else {
        updatedOptions = {
          filterLanguageId: action.payload.languageId,
        };
      }

      // Set new options for product
      return {
        ...state,
        productOptions: {
          ...productOptions,
          [action.payload.productId]: updatedOptions,
        },
      };
    }
    default:
      return state;
  }
}
