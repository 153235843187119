import { type MutableRefObject, type ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import usePhraseTest, { actions as phraseTestActions } from "../../../hooks/usePhraseTest/usePhraseTest";
import Instructions from "./includes/Instructions";
import LessonContext from "../../../context/LessonContext";
import PhraseTestContext from "../includes/context";
import { Phrasebox } from "../../PhraseboxFacelift/PhraseboxFacelift";
import RateableTest from "../RateableTest";
import { RateableTestTypeIds } from "../../../utils/constants";
import type { RatedPhrase } from "../../../hooks/useRocketRecord/types";
import useIsFocusedWithin from "../../../hooks/useIsFocusedWithin";
import useTranslation from "../../../hooks/useTranslation";
import { noop } from "../../../utils";
import { Button, Checkbox, RoundedButton } from "@rocketlanguages/ui";
import Voice from "../../Phrasebox/includes/RocketRecord/Voice";
import AudioRecorder from "../../Phrasebox/includes/RocketRecord/AudioRecorder";
import useCurrentRef from "../../../hooks/useCurrentRef";
import { useSyncedBooleanPreference } from "../../../hooks/usePreference";
import { useSharedStore } from "../../../store";
import { RateableTestUIContext } from "../RateableTestUI/includes/context";
import type { Phrase } from "@rocketlanguages/types";
import { useWebRocketRecord } from "../../PhraseboxFacelift/PhraseboxFacelift";
import { clsx } from "clsx";
import { MissileRatingButtons } from "../RateableTestUI/buttons/MissileRatingButtons";
import { getRecommendedRatingButton } from "../RateableTestUI/buttons/getRecommendedRatingButton";
import type { RatingGroups } from "../../../hooks/usePhraseTest/useRatingGroups";
import usePhraseHotkeys from "../../../hooks/usePhraseHotkeys";

export function HearItFacelift({ rateableTestId }: { rateableTestId: number }) {
  const t = useTranslation();
  const lesson = useContext(LessonContext);
  const phraseTest = usePhraseTest({
    testTypeId: RateableTestTypeIds.HEAR_IT,
    lessonId: lesson.id,
    rateableTestId,
    mode: "unrated_components",
  });

  return (
    <RateableTest
      phraseTest={phraseTest}
      testTypeId={RateableTestTypeIds.HEAR_IT}
      testName={t("hear-it-say-it")}
      testSubheading={t("hear-it-say-it-subheading")}
      instructions={<Instructions />}
      settings={<HearItSettings />}
    >
      <HearItPhraseTest />
    </RateableTest>
  );
}

function HearItSettings() {
  const [autoRecord, setAutoRecord] = useSyncedBooleanPreference("listening_auto_record", false);
  const uiContext = useContext(RateableTestUIContext);

  return (
    <div>
      <h4 className="mb-2">Options</h4>
      <div className="mb-4">
        <Checkbox
          checked={Boolean(autoRecord)}
          onChange={() => {
            setAutoRecord(!autoRecord);
          }}
          label={"Record automatically after tutor audio plays"}
        />
      </div>
      <div className="my-4 flex justify-center sm:my-6">
        <Button
          color="primary"
          onClick={() => {
            uiContext?.toggleSettings();
          }}
        >
          CONFIRM
        </Button>
      </div>
    </div>
  );
}

export function HearItPhraseTest() {
  const { testContainerRef, phraseTest } = useContext(PhraseTestContext);
  const currentTestIsFocused = useIsFocusedWithin(testContainerRef);
  const focusTestContainer = () => testContainerRef.current?.focus({ preventScroll: true });
  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];
  const isRevealed = phraseTest.state.revealed.has(phraseTest.state.index);
  const t = useTranslation();

  const onReveal = () => {
    phraseTest.methods.dispatch(phraseTestActions.reveal(0));
    focusTestContainer();
  };

  const onRate = (ratingLabel: keyof RatingGroups) => {
    phraseTest.methods.rate(ratingLabel);
    focusTestContainer();
  };

  usePhraseHotkeys({ phraseTestRef: testContainerRef, revealed: isRevealed, onReveal, onRate });

  return (
    <div className="flex h-full flex-col gap-4 pt-6">
      {currentPhrase && (
        <HearItPhrasebox
          phrase={currentPhrase}
          index={phraseTest.state.index}
          revealed={phraseTest.state.revealed.has(phraseTest.state.index)}
          onReveal={(ratingLevel) => phraseTest.methods.dispatch(phraseTestActions.reveal(ratingLevel))}
          onSetSuggestedRatingLevel={(ratingLevel) =>
            phraseTest.methods.dispatch(phraseTestActions.setSuggestedRatingLevel(ratingLevel))
          }
          handleKeyEvents={currentTestIsFocused}
        />
      )}
      <div className="flex h-20 flex-col items-center justify-end">
        {isRevealed ? (
          <MissileRatingButtons
            text="How hard did you find this phrase?"
            recommended={getRecommendedRatingButton(phraseTest.state.revealed.get(phraseTest.state.index))}
            onRate={onRate}
            numEasy={phraseTest.computed.ratings.easy.size}
            numGood={phraseTest.computed.ratings.good.size}
            numHard={phraseTest.computed.ratings.hard.size}
          />
        ) : (
          <RoundedButton
            className="w-full max-w-60 border border-missilebrand font-semibold text-missilebrand hover:bg-missilebrand hover:text-white dark:bg-missilebrand dark:text-white"
            onClick={onReveal}
          >
            {t("reveal")}
          </RoundedButton>
        )}
      </div>
    </div>
  );
}

interface HearItPhraseboxProps {
  index: number;
  phrase: Phrase;
  revealed: boolean;
  onReveal: (ratingLevel: number) => void;
  onSetSuggestedRatingLevel: (ratingLevel: number) => void;
  children?: ReactNode;
  handleKeyEvents: boolean;
}

export function HearItPhrasebox({
  index,
  phrase,
  children,
  revealed: isRevealed,
  onReveal,
  onSetSuggestedRatingLevel,
  handleKeyEvents,
}: HearItPhraseboxProps) {
  const onRevealRef = useRef(onReveal);
  const onSetSuggestedRatingLevelRef = useRef(onSetSuggestedRatingLevel);
  onRevealRef.current = onReveal;
  onSetSuggestedRatingLevelRef.current = onSetSuggestedRatingLevel;

  const onRecordFinish = useCallback(
    ({ result }: { phraseId: number; result?: RatedPhrase; blobUrl?: string }) => {
      if (!result) {
        return;
      }

      if (result.ratingLevel === 3) {
        onRevealRef.current(result.ratingLevel);
        return;
      }

      if (result.percentage > 10) {
        if (isRevealed) {
          onRevealRef.current(result.ratingLevel);
        } else {
          onSetSuggestedRatingLevelRef.current(result.ratingLevel);
        }
      }
    },
    [isRevealed],
  );

  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);

  const rocketRecord = useWebRocketRecord({
    phrase,
    onRecordFinish,
  });

  useEffect(() => {
    return () => {
      const { actions, flag } = rocketRecord.useRocketRecordState.getState();
      if (["ACTIVE", "STARTING", "FINISHING"].includes(flag.status)) {
        // Clear speech timeouts
        actions.clearTimeouts();
        AudioRecorder?.finishRecording(false);
        Voice.stop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const playing = useHearItPlayerAndRecorder({
    audioRef: audioPlayerRef,
    index,
    startRecording: rocketRecord.methods.start,
  });

  const hasMoreThanOneWritingSystem = phrase.strings.length > 2;
  const hasNotations = !!phrase.literal_string || phrase.strings.some((s) => s.notations.length > 0);

  return (
    <Phrasebox rocketRecord={rocketRecord}>
      {children}
      {handleKeyEvents && <Phrasebox.Hotkeys />}
      <div className="relative">
        <div
          className={clsx(
            "rounded-2xl border-2 border-missilestroke p-4 dark:bg-missilesurfacelight",
            isRevealed && hasNotations && "rounded-tr-none",
          )}
        >
          {isRevealed && hasNotations ? <Phrasebox.Notations /> : null}

          <Phrasebox.RowLayout>
            <Phrasebox.PlayButton
              audioRef={audioPlayerRef}
              className="bg-missilesurfacedark text-missilebrand hover:bg-gray-300 dark:text-white hover:dark:bg-gray-600"
              useDarkWaveform
            />
            <Phrasebox.StringsContainer>
              <Phrasebox.Strings>
                {(phraseString, index, items) => {
                  const isLast = index === items.length - 1;
                  const shouldShowBottomBorder = hasMoreThanOneWritingSystem && !isLast;
                  return (
                    <div
                      className={clsx(
                        shouldShowBottomBorder ? "mb-2 w-full border-b border-b-missilestroke pb-2" : undefined,
                        !isRevealed && "select-none",
                      )}
                    >
                      <Phrasebox.String
                        {...phraseString}
                        text={!isRevealed ? "..." : phraseString.text}
                        disableVocabUnderline
                      />
                    </div>
                  );
                }}
              </Phrasebox.Strings>
            </Phrasebox.StringsContainer>
          </Phrasebox.RowLayout>
          <Phrasebox.RowLayout className="h-6 items-center">
            <Phrasebox.RocketRecordRatingButton />
            <div className="mb-2 flex-1 border-b border-b-missilestroke pb-2" />
          </Phrasebox.RowLayout>

          <Phrasebox.RowLayout className="items-center pt-2">
            <Phrasebox.RecordButton
              disabled={playing}
              className="hover:bg-missileaccent/90 bg-missileaccent text-white"
            />

            <Phrasebox.SpeechLayout className="flex-1">
              <Phrasebox.Speech />
              <Phrasebox.ErrorText />
              <Phrasebox.DebugPanel />
            </Phrasebox.SpeechLayout>

            <Phrasebox.RecordPlaybackButton
              className="bg-missilesurfacedark text-missilebrand hover:bg-gray-300 dark:text-white hover:dark:bg-gray-600"
              useDarkWaveform
            />
          </Phrasebox.RowLayout>
        </div>
      </div>
    </Phrasebox>
  );
}

/**
 * Plays audio when the index changes
 *
 * Keeps track of play state
 */
const useHearItPlayerAndRecorder = (params: {
  audioRef: MutableRefObject<HTMLAudioElement | null>;
  index: number;
  startRecording: () => Promise<void>;
}) => {
  const { audioRef, index, startRecording } = params;
  const [playing, setPlaying] = useState(true);
  const startRecordingRef = useCurrentRef(startRecording);
  const store = useSharedStore();

  const indexRecordedAtRef = useRef<number | null>(null);

  useEffect(() => {
    // Register listener for when audio finishes
    const audio = audioRef?.current;
    if (!audio) {
      setPlaying(false);
      return noop;
    }
    // Set a timeout for ~10 seconds to enable the user to record
    const timeout = setTimeout(() => {
      setPlaying(false);
    }, 15000);

    let recordTimeoutBuffer: ReturnType<typeof setTimeout> | null = null;

    const handleEnded = () => {
      clearTimeout(timeout);
      if (indexRecordedAtRef.current !== index) {
        indexRecordedAtRef.current = index;

        const autoRecord = store.getState().user.preferences?.listening_auto_record;

        const shouldAutoRecord = Boolean(autoRecord === "1" || autoRecord === 1);

        if (!shouldAutoRecord) {
          setPlaying(false);
          return;
        }

        recordTimeoutBuffer = setTimeout(() => {
          const fn = startRecordingRef.current;
          if (fn) {
            fn().finally(() => {
              setPlaying(false);
            });
          } else {
            setPlaying(false);
          }
        }, 500);
      } else {
        setPlaying(false);
      }
    };
    audio.addEventListener("ended", handleEnded);

    const player = audioRef.current;

    // Play the audio
    const playTimeoutBuffer = setTimeout(() => {
      setPlaying(true);
      if (player) {
        player.currentTime = 0;
        player.play();
      }
    }, 250);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      player?.pause();

      clearTimeout(timeout);
      clearTimeout(playTimeoutBuffer);
      if (recordTimeoutBuffer) {
        clearTimeout(recordTimeoutBuffer);
      }
    };
  }, [audioRef, index, startRecordingRef, store]);

  return playing;
};
