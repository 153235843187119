import { DragDropContext, Draggable, type DropResult, Droppable } from "react-beautiful-dnd";
import { Phrasebox, useWebRocketRecord } from "../../PhraseboxFacelift/PhraseboxFacelift";
import { RateableTestTypeIds, WritingSystemIds, WritingSystemLanguages } from "../../../utils/constants";
import {
  allPunctuationRegexGlobal,
  getRatingLevel,
  getWritingSystemIdFromCourse,
  getWritingSystemIndex,
  languageSplitCharacters,
  reorderArray,
} from "../../../utils";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import usePhraseTest, {
  actions as phraseTestActions,
  type usePhraseTestProps,
} from "../../../hooks/usePhraseTest/usePhraseTest";
import Instructions from "./includes/Instructions";
import LessonContext from "../../../context/LessonContext";
import RateableTest from "../RateableTest";
import RateableTestContext from "../includes/context";
import { RateableTestUIContext } from "../RateableTestUI/includes/context";
import type { Phrase, WriteItItem } from "@rocketlanguages/types";
import { clsx } from "clsx";
import getCharacterButtons from "./utils/getCharacterButtons";
import shuffler from "./utils/shuffler";
import { useSharedSelector } from "../../../store";
import useTranslation from "../../../hooks/useTranslation";
import { RoundedButton } from "@rocketlanguages/ui";
import { MissileRatingButtons } from "../RateableTestUI/buttons/MissileRatingButtons";
import { getRecommendedRatingButton } from "../RateableTestUI/buttons/getRecommendedRatingButton";
import usePhraseHotkeys from "../../../hooks/usePhraseHotkeys";
import type { RatingGroups } from "../../../hooks/usePhraseTest/useRatingGroups";

type SortItProps = {
  rateableTestId: number;
  rateableTestTypeId: number;
};

export function SortItFacelift({ rateableTestId, rateableTestTypeId }: SortItProps) {
  const t = useTranslation();
  const lesson = useContext(LessonContext);
  const activeCourseSlug = useSharedSelector((store) => store.preferences.activeCourse?.slug) || "";
  const isNative = rateableTestTypeId === RateableTestTypeIds.SORT_IT_NATIVE;

  const phraseTestProps = useMemo(
    (): usePhraseTestProps => ({
      testTypeId: rateableTestTypeId,
      lessonId: lesson.id,
      rateableTestId,
      mode: "unrated_components",
      phraseFilter(phrase) {
        // filter out duplicate kanji/kana phrases from kanji sort it
        if (phrase.course_id === 1 && isNative) {
          if (
            !phrase.strings[0] ||
            // Note unusual phrase string in RJ 3.8 with one string entry.
            !phrase.strings[1] ||
            phrase.strings[0].text.replace(allPunctuationRegexGlobal, "") ===
              phrase.strings[1].text.replace(allPunctuationRegexGlobal, "")
          ) {
            return false;
          }
        }

        const mainWsIndex = getWritingSystemIndex(activeCourseSlug, phrase, isNative);

        const wsId = phrase.strings[mainWsIndex]?.writing_system_id;

        if (!wsId) {
          return false;
        }

        const splitCharacter =
          wsId in languageSplitCharacters
            ? languageSplitCharacters[wsId]!
            : languageSplitCharacters[WritingSystemIds.english]!;

        const phraseStringArray = (phrase.strings[mainWsIndex]?.text || "").split(splitCharacter).filter((s) => !!s);

        return phraseStringArray.length > 1 && phraseStringArray[0] !== phraseStringArray[1];
      },
    }),
    [rateableTestTypeId, lesson.id, rateableTestId, isNative, activeCourseSlug],
  );

  const phraseTest = usePhraseTest(phraseTestProps);

  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];

  const phraseStringIndex = getWritingSystemIndex(activeCourseSlug, currentPhrase, isNative);

  // Get testName for romanized romantic languages, eg: Pinyin
  const testName = getTestName({
    currentPhrase,
    rateableTestTypeId,
    activeCourseSlug,
    isNative,
    phraseStringIndex,
  });

  return (
    <RateableTest
      phraseTest={phraseTest}
      testTypeId={rateableTestTypeId}
      testName={`${t("sort-it")} (${testName})`}
      testSubheading={t("sort-it-subheading")}
      instructions={<Instructions />}
    >
      <SortItPhraseTest />
    </RateableTest>
  );
}

function SortItPhraseTest() {
  const t = useTranslation();
  const { testContainerRef, testTypeId, phraseTest } = useContext(RateableTestContext);

  const activeCourseSlug = useSharedSelector((store) => store.preferences.activeCourse?.slug) || "";
  const [hideAnswers, setHideAnswers] = useState(false);
  const isArabic = activeCourseSlug === "arabic";

  const [topArray, setTopArray] = useState<WriteItItem[]>([]);
  const [bottomArray, setBottomArray] = useState<WriteItItem[]>([]);

  const isRevealed = phraseTest.state.revealed.has(phraseTest.state.index);
  const currentPhrase = phraseTest.components.testPhrases[phraseTest.state.index];

  const phraseStringIndex = getWritingSystemIndex(
    activeCourseSlug,
    currentPhrase,
    testTypeId === RateableTestTypeIds.SORT_IT_NATIVE,
  );

  useEffect(() => {
    if (!currentPhrase) {
      return;
    }
    setBottomArray((currentState) => {
      if (currentState && currentState.length !== 0) {
        return currentState;
      }
      const buttons = getCharacterButtons({ phrase: currentPhrase, phraseStringIndex });

      const shuffledButtons = shuffler({
        phrases: phraseTest.components.testPhrases,
        phrase: currentPhrase,
        phraseArray: buttons,
        phraseStringIndex,
      });

      return shuffledButtons || [];
    });
  }, [currentPhrase, phraseStringIndex, phraseTest.components.testPhrases]);

  const handleReveal = useCallback(() => {
    const { correctCount, correctAnswers } = getCorrectAnswers({
      topArray,
      bottomArray,
    });

    setBottomArray(correctAnswers);

    // If the user answered all questions correctly, hide the answers
    if (correctCount === topArray.length && bottomArray.length === 0) {
      setHideAnswers(true);
    }

    const rating = calculateRating({
      correctCount,
      topArrayLength: topArray.length,
      bottomArrayLength: bottomArray.length,
    });

    const ratingLevel = getRatingLevel(rating);

    phraseTest.methods.dispatch(phraseTestActions.reveal(ratingLevel));
  }, [topArray, bottomArray, phraseTest.methods]);

  const onSelectAnswer = (phraseItem: WriteItItem) => {
    setTopArray((answers) => answers.concat(phraseItem));
    setBottomArray((arr) => arr?.filter((item) => item !== phraseItem) || []);
  };

  const onRemoveAnswer = (answerItem: WriteItItem, destinationIndex = bottomArray.length) => {
    if (isRevealed) {
      return;
    }
    const currentAnswerIndex = topArray.findIndex((answer) => answer.originalOrder === answerItem.originalOrder);
    if (currentAnswerIndex === -1) {
      return;
    }
    setTopArray((state) => {
      const newArray = [...state];
      newArray.splice(currentAnswerIndex, 1);
      return newArray;
    });

    setBottomArray((state) => {
      if (!state) {
        return state;
      }
      return [...state.slice(0, destinationIndex), answerItem, ...state.slice(destinationIndex)];
    });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const destinationIndex = result.destination.index;
    const sourceIndex = result.source.index;

    // The user just reordered the list
    if (result.destination.droppableId === "questions" && result.source.droppableId === "questions") {
      setBottomArray((state) => reorderArray(state as any[], sourceIndex, destinationIndex));
    }

    // drag from questions to answers
    if (result.source.droppableId === "questions" && result.destination.droppableId === "answers") {
      const phraseItem = bottomArray[sourceIndex];
      if (phraseItem) {
        setTopArray((answers) => {
          const newState = [...answers];
          newState.splice(destinationIndex, 0, phraseItem);
          return newState;
        });
      }
      setBottomArray((state) => {
        if (!state) {
          return state;
        }
        return [...state.slice(0, sourceIndex), ...state.slice(sourceIndex + 1)];
      });
    }
    // drag within answers
    if (result.source.droppableId === "answers" && result.destination.droppableId === "answers") {
      setTopArray((state) => reorderArray(state, sourceIndex, destinationIndex));
    }
    // drag from answers to questions
    if (result.source.droppableId === "answers" && result.destination.droppableId === "questions") {
      const answerItem = topArray[result.source.index];
      if (answerItem) {
        onRemoveAnswer(answerItem, destinationIndex);
      }
    }
  };

  const handleRate = useCallback(
    (ratingLabel: keyof RatingGroups) => {
      phraseTest.methods.rate(ratingLabel);
      setHideAnswers(false);
      setTopArray([]);
      setBottomArray([]);
    },
    [phraseTest],
  );

  usePhraseHotkeys({
    phraseTestRef: testContainerRef,
    revealed: isRevealed,
    onReveal: handleReveal,
    onRate: handleRate,
  });

  if (!currentPhrase) {
    return null;
  }

  return (
    <div className="space-y-6 pt-6">
      <div>
        <SortItPhrasebox phrase={currentPhrase} phraseTest={phraseTest} />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable key={"questions"} droppableId={"questions"} direction="horizontal">
            {(provided) => (
              <div
                className={clsx("flex min-h-24 w-full flex-wrap items-center", isArabic && "flex-row-reverse")}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {!hideAnswers &&
                  bottomArray.map((writeItItem, i) => {
                    if (!writeItItem) {
                      // "undefined is not an object (evaluating 'writeItItem.originalOrder')"
                      return null;
                    }
                    return (
                      <Draggable
                        key={writeItItem.originalOrder}
                        isDragDisabled={isRevealed || isArabic}
                        draggableId={`question.${writeItItem.originalOrder}`}
                        index={i}
                      >
                        {(provided) => (
                          <div
                            id={writeItItem.questionText}
                            onClick={!isRevealed ? () => onSelectAnswer(writeItItem) : undefined}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={clsx("group h-min px-1", isArabic && "ws-arabic")}
                          >
                            <div
                              className={clsx(
                                "select-none rounded border p-2",
                                isRevealed
                                  ? "border-missilegreen bg-green-100 text-missilegreen dark:border-missilegreen dark:bg-missilegreen dark:text-green-100"
                                  : "border-missilesurfacedark bg-missilesurfacelight group-hover:cursor-pointer group-hover:bg-missilesurfacedark",
                              )}
                            >
                              {writeItItem.questionText}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable key={"answers"} droppableId={"answers"} direction="horizontal">
            {(provided, snapshot) => (
              <div
                className={clsx(
                  "flex min-h-24 w-full rounded-md border border-missilestroke p-2 transition-all",
                  snapshot.isDraggingOver
                    ? "bg-rocketblue-extra-light dark:bg-missilesurfacedark"
                    : "bg-white dark:bg-missilesurfacelight",
                  isArabic ? "flex-row-reverse" : "flex-wrap",
                )}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {topArray.map((answerItem, i) => (
                  <Draggable
                    key={answerItem.originalOrder}
                    isDragDisabled={isRevealed || isArabic}
                    draggableId={`answer.${answerItem.originalOrder}`}
                    index={i}
                  >
                    {(provided) => (
                      <div
                        id={`answer${i}`}
                        className={clsx("h-min select-none px-1", isArabic && "ws-arabic")}
                        onClick={() => onRemoveAnswer(answerItem)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div
                          className={clsx("rounded border p-2", {
                            "border-missilegreen bg-green-100 text-missilegreen dark:border-missilegreen dark:bg-missilegreen dark:text-green-100":
                              isRevealed && answerItem.correct,
                            "border-missileaccent bg-red-100 text-missileaccent dark:border-missileaccent dark:bg-missileaccent dark:text-red-100":
                              isRevealed && !answerItem.correct,
                            "border-missilesurfacedark bg-missilesurfacelight hover:cursor-pointer hover:bg-missilesurfacedark":
                              !isRevealed,
                          })}
                        >
                          {answerItem.questionText}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <div className="flex h-20 flex-col items-center justify-end">
        {isRevealed ? (
          <MissileRatingButtons
            text="How hard did you find this phrase?"
            recommended={getRecommendedRatingButton(phraseTest.state.revealed.get(phraseTest.state.index))}
            onRate={handleRate}
            numEasy={phraseTest.computed.ratings.easy.size}
            numGood={phraseTest.computed.ratings.good.size}
            numHard={phraseTest.computed.ratings.hard.size}
          />
        ) : (
          <RoundedButton
            className="w-full max-w-60 border border-missilebrand font-semibold text-missilebrand hover:bg-missilebrand hover:text-white dark:bg-missilebrand dark:text-white"
            onClick={handleReveal}
          >
            {t("reveal")}
          </RoundedButton>
        )}
      </div>
    </div>
  );
}

function getTestName({
  currentPhrase,
  rateableTestTypeId,
  activeCourseSlug,
  isNative,
  phraseStringIndex,
}: {
  currentPhrase?: Phrase;
  rateableTestTypeId?: number;
  activeCourseSlug: string;
  isNative: boolean;
  phraseStringIndex: number;
}) {
  if (!currentPhrase || !rateableTestTypeId) {
    const wsId = getWritingSystemIdFromCourse(activeCourseSlug, isNative) ?? Number(isNative);
    return WritingSystemLanguages[wsId] || "";
  }
  const phraseString = currentPhrase.strings[phraseStringIndex];
  if (!phraseString) {
    const wsId = getWritingSystemIdFromCourse(activeCourseSlug, isNative) ?? Number(isNative);
    return WritingSystemLanguages[wsId] || "";
  }
  return WritingSystemLanguages[phraseString.writing_system_id] || "";
}

function getCorrectAnswers({ topArray, bottomArray }: { topArray: WriteItItem[]; bottomArray: WriteItItem[] }) {
  // if answer is undefined, it is a shuffled array, determine getratinglevel from order
  // and re-order question array back into its correct order, including highlighting which phrases were in the correct order
  let correctCount = 0;

  topArray.forEach((answer, i) => {
    // if an answer is in the same position in the answers array as it is in the original index
    // set its answer value to true and add to the correct count
    // and the corresponding value in the answeredbottomArray to true
    if (i === answer.originalOrder) {
      answer.correct = true;
      correctCount++;
      return;
    }

    // Else handle the possibility that the answer was a duplicate value that the user had no way of determining the original index of
    const duplicateAnswers = topArray.filter((el) => el.questionText === answer.questionText);

    if (duplicateAnswers.length > 1) {
      for (const duplicateAnswer of duplicateAnswers) {
        if (i === duplicateAnswer.originalOrder) {
          answer.correct = true;
          correctCount++;
        }
      }
    }
  });

  // Sort the answered array by original order
  const correctAnswers = [...topArray, ...bottomArray].sort((a, b) => (a.originalOrder || 0) - (b.originalOrder || 0));

  return { correctCount, correctAnswers };
}

function calculateRating({
  correctCount,
  topArrayLength,
  bottomArrayLength,
}: {
  correctCount: number;
  topArrayLength: number;
  bottomArrayLength: number;
}) {
  // if the user got no answers right, check if they attempted or not
  if (correctCount) {
    return (correctCount / topArrayLength) * 100;
  }
  // if attempted, return hard suggestion, otherwise highlight all buttons
  return bottomArrayLength ? 1 : 0;
}

function SortItPhrasebox(props: { phrase: Phrase; phraseTest: ReturnType<typeof usePhraseTest> }) {
  const isRevealed = props.phraseTest.state.revealed.has(props.phraseTest.state.index);
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
  const uiContext = useContext(RateableTestUIContext);

  const rocketRecord = useWebRocketRecord({
    phrase: props.phrase,
  });

  useEffect(() => {
    if (!uiContext?.instructionsVisible) {
      audioPlayerRef.current?.play();
    }
  }, [props.phraseTest.state.index, audioPlayerRef, uiContext?.instructionsVisible]);

  const hasMoreThanOneWritingSystem = props.phrase.strings.length > 2;
  const hasNotations = !!props.phrase.literal_string || props.phrase.strings.some((s) => s.notations.length > 0);

  return (
    <Phrasebox rocketRecord={rocketRecord}>
      <div className="relative">
        <div
          className={clsx(
            "rounded-2xl border-2 border-missilestroke p-4 dark:bg-missilesurfacelight",
            hasNotations && "rounded-tr-none",
          )}
        >
          <Phrasebox.Notations />
          <Phrasebox.RowLayout>
            <Phrasebox.PlayButton
              audioRef={audioPlayerRef}
              className={clsx(
                "hover:bg-gray-300",
                "bg-missilesurfacedark text-missilebrand dark:text-white hover:dark:bg-gray-600",
              )}
              useDarkWaveform
            />
            <Phrasebox.StringsContainer>
              <Phrasebox.Strings>
                {(phraseString, index, items) => {
                  const hide = !isRevealed;
                  const isLast = index === items.length - 1;
                  const shouldShowBottomBorder = hasMoreThanOneWritingSystem && !isLast;

                  if (hide) {
                    return (
                      <div
                        className={
                          shouldShowBottomBorder ? "mb-2 w-full border-b border-b-missilestroke pb-2" : undefined
                        }
                      >
                        <Phrasebox.String {...phraseString} text="..." />
                      </div>
                    );
                  }

                  return (
                    <div
                      className={
                        shouldShowBottomBorder ? "mb-2 w-full border-b border-b-missilestroke pb-2" : undefined
                      }
                    >
                      <Phrasebox.String {...phraseString} />
                    </div>
                  );
                }}
              </Phrasebox.Strings>
            </Phrasebox.StringsContainer>
          </Phrasebox.RowLayout>
          <Phrasebox.RowLayout className="h-6 items-center">
            <Phrasebox.RocketRecordRatingButton />
            <div className="mb-2 flex-1 border-b border-b-missilestroke pb-2" />
          </Phrasebox.RowLayout>

          <Phrasebox.RowLayout className="items-center pt-2">
            <Phrasebox.RecordButton
              disabled={!isRevealed}
              className={clsx("hover:bg-missileaccent/90 bg-missileaccent text-white", !isRevealed && "opacity-50")}
            />

            <Phrasebox.SpeechLayout className="flex-1">
              <Phrasebox.Speech />
              <Phrasebox.ErrorText />
              <Phrasebox.DebugPanel />
            </Phrasebox.SpeechLayout>

            <Phrasebox.RecordPlaybackButton
              className="bg-missilesurfacedark text-missilebrand hover:bg-gray-300 dark:text-white hover:dark:bg-gray-600"
              useDarkWaveform
            />
          </Phrasebox.RowLayout>
        </div>
      </div>
    </Phrasebox>
  );
}
