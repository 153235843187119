import { useMemo } from "react";
import { useStoreSelector } from "~/store";

/** Filters out draft components for regular users */
const useLessonComponents = (lessonId: number) => {
  const lessonComponents = useStoreSelector((store) => store.lesson.entities.lesson_components[lessonId]);
  const debugEnabled = useStoreSelector((state) => state.preferences.debugEnabled);

  const filteredLessonComponents = useMemo(() => {
    if (!lessonComponents) {
      return [];
    }
    if (debugEnabled) {
      return lessonComponents;
    }
    return lessonComponents.filter((_component) => _component.status === "live");
  }, [debugEnabled, lessonComponents]);

  return filteredLessonComponents;
};

export default useLessonComponents;
