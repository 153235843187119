import * as constants from "./constants";

import type { Course, Product } from "@rocketlanguages/types";

import { action } from "typesafe-actions";

export const setDebugEnabled = (debugEnabled: boolean) => action(constants.DEBUG, debugEnabled);
export const setShowDraftComponents = (showDraftComponents: boolean) =>
  action(constants.SHOW_DRAFT_COMPONENTS, showDraftComponents);

export function setActiveCourseAndProduct(course: Course, product: Product) {
  return action(constants.SET_ACTIVE_COURSE_AND_PRODUCT, { course, product });
}

export function setActiveProduct(product?: Product) {
  return action(constants.SET_ACTIVE_PRODUCT, { product });
}

export function setActiveCourse(course: Course) {
  return action(constants.SET_ACTIVE_COURSE, { course });
}

export function setProductLanguageFilter(productId: number, languageId: "all" | number) {
  return action(constants.SET_PRODUCT_LANGUAGE_FILTER, {
    languageId,
    productId,
  });
}
